@import './reset.scss';
@import './variables.scss';

// Diseño base:
// - Desktop 1280px

// Media queries:
// - Tablet: max-width: 1024px
// - Large mobile: max-width: 748px
// - Mobile: max-width: 440px

* {
  box-sizing: border-box;
}

body {
  font-family: $Merriweather;
}

h1 {
  font-family: $Rubik;
  font-size: 68px;
  font-weight: 500;
  line-height: 1.09;
  color: #333;

  @media (max-width: $first) {
    font-size: 58px;
    line-height: 1.1;
  }

  @media (max-width: $second) {
    font-size: 48px;
    line-height: 1.13;
  }

  @media (max-width: $third) {
    font-size: 38px;
    line-height: 1.11;
  }
}

h2 {
  font-family: $Rubik;
  font-size: 60px;
  font-weight: 500;
  line-height: 1.07;
  color: #333;

  @media (max-width: $first) {
    font-size: 50px;
    line-height: 1.08;
  }

  @media (max-width: $second) {
    font-size: 40px;
    line-height: 1.1;
    color: #333;
  }

  @media (max-width: $third) {
    font-size: 34px;
    line-height: 1.12;
    color: #333;
  }
}

h3 {
  font-family: $Rubik;
  font-size: 40px;
  font-weight: 500;
  line-height: 1;
  color: #333;

  @media (max-width: $first) {
    font-size: 36px;
    line-height: 1.11;
  }

  @media (max-width: $second) {
    font-size: 30px;
    line-height: 1.13;
  }

  @media (max-width: $third) {
    font-size: 28px;
    line-height: 1.14;
  }
}

h4 {
  font-family: $Rubik;
  font-size: 28px;
  font-weight: 500;
  line-height: 1.14;
  color: #333333;

  @media (max-width: $first) {
    font-size: 26px;
    line-height: 1.15;
  }

  @media (max-width: $second) {
    font-size: 24px;
    line-height: 1.17;
  }

  @media (max-width: $third) {
    font-size: 22px;
    line-height: 1.18;
  }
}

h5 {
  font-family: $Rubik;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.3;
}

.container {
  max-width: 1280px;
  padding: 0 48px;
  margin: 0 auto;

  @media (max-width: $second) {
    padding: 0 24px;
  }

  @media (max-width: $third) {
    padding: 0 16px;
  }
}

.container-slim {
  max-width: 1280px;
  padding: 0 96px;
  margin: 0 auto;

  @media (max-width: $first) {
    padding: 0 48px;
  }

  @media (max-width: $second) {
    padding: 0 24px;
  }

  @media (max-width: $third) {
    padding: 0 16px;
  }
}

a,
a:visited,
a:active,
a:hover {
  color: #263be1;
  text-decoration: none;
}

input {
  width: 274px;
  line-height: 42px;
  border-radius: 2px;
  border: 1px solid #979797;
  margin-right: 16px;
  background-color: transparent;
  font-size: 16px;
  font-weight: 300;
  padding: 0 16px;

  &::placeholder {
    font-size: 16px;
    font-weight: 300;
    color: #969696;
  }

  @media (max-width: $second) {
    line-height: 38px;
  }
}

button {
  display: inline-block;
  line-height: 44px;
  border-radius: 2px;
  background-color: #263be1;
  font-family: $Rubik;
  font-size: 16px;
  font-weight: 500;
  padding: 0 28px;
  color: #fff;
  cursor: pointer;
  border: none;

  @media (max-width: $second) {
    line-height: 40px;
  }

  @media (max-width: $third) {
    font-size: 14px;
    padding: 0 21px;
  }
}

.UI-subtitle {
  font-family: $Rubik;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.06;
  color: #333;

  @media (max-width: $second) {
    font-size: 16px;
  }
}

.UI-body {
  font-family: $Merriweather;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.67;
  color: #424242;

  @media (max-width: $second) {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.63;
  }
}

.UI-highlight {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.6;
  color: #424242;

  @media (max-width: $second) {
    font-size: 18px;
    line-height: 1.67;
  }

  @media (max-width: $third) {
    font-size: 16px;
    line-height: 1.63;
  }
}

.UI-link {
  font-family: $Rubik;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.19;
  color: #263be1;

  @media (max-width: $third) {
    font-size: 14px;
    line-height: 1.36;
  }
}

.UI-small1 {
  font-family: $Merriweather;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.63;
  color: #424242;

  @media (max-width: $second) {
    font-size: 14px;
    line-height: 1.57;
  }
}

.UI-small2 {
  font-family: $Merriweather;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.63;
  font-style: italic;
  color: #424242;

  @media (max-width: $second) {
    font-size: 14px;
    line-height: 1.57;
  }
}

.UI-caption {
  font-family: $Merriweather;
  font-size: 14px;
  font-weight: 300;
  font-style: italic;
  line-height: 1.43;
  color: #424242;
}

ul {
  li {
    &:before {
      content: '·';
      font-size: 50px;
      line-height: 30px;
      float: left;
    }

    p {
      margin-left: 40px;
    }
  }
}

b {
  font-weight: 900;
}
