@import './../../styles/variables.scss';

.Home {
  .screen {
    height: calc(100vh - 64px);
    display: flex;
    align-items: center;

    @media (max-width: $third) {
      height: unset;
      padding: 38px 0 46px;
    }

    h1 {
      max-width: 1000px;

      @media (max-width: $second) {
        max-width: 100%;
      }
    }

    .description {
      max-width: 700px;
      margin-top: 24px;
      margin-bottom: 96px;

      @media (max-width: $first) {
        max-width: 600px;
      }

      @media (max-width: $second) {
        max-width: 100%;
        margin-bottom: 72px;
      }

      @media (max-width: $third) {
        margin-top: 16px;
        margin-bottom: 24px;
      }
    }

    .links {
      display: flex;

      > .link {
        margin-right: 40px;

        @media (max-width: $second) {
          margin-right: 21px;
        }

        &:last-child {
          margin-right: 0;
        }

        &.--email {
          .email {
            @media (max-width: $third) {
              display: none;
            }
          }

          .contact {
            display: none;

            @media (max-width: $third) {
              display: unset;
            }
          }
        }
      }
    }
  }

  > .access {
    padding: 96px 0;
    background-color: #eee;
    text-align: center;

    @media (max-width: $second) {
      padding: 80px 0;
    }

    @media (max-width: $third) {
      padding: 64px 0;
    }

    h3 {
      svg {
        fill: #333;
      }
    }

    .description {
      max-width: 350px;
      color: #424242;
      margin: 16px auto 40px;

      @media (max-width: $second) {
        max-width: 300px;
      }
    }

    .input-wrapper {
      display: flex;
      justify-content: center;

      input {
        width: 274px;
        margin-right: 16px;
        vertical-align: middle;

        @media (max-width: $second) {
          width: 220px;
        }

        @media (max-width: $third) {
          width: 180px;
        }
      }
    }

    .error {
      text-align: left;
      color: #ad1f1f;
      margin-top: 8px;
    }
  }
}
