@import '../../styles/variables.scss';

.Video {
  &__wrapper {
    background-color: #fff;
    padding: 48px;

    @media (max-width: $first) {
      padding: 32px;
    }

    @media (max-width: $third) {
      padding: 16px;
    }
  }

  &__media {
    max-width: 638px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }

  &__image {
    width: calc((100% - 38px) / 2);
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.12);
    height: 100%;

    img {
      max-width: 100%;
      display: block;
    }

    @media (max-width: $third) {
      display: none;
    }
  }

  &__video {
    width: calc((100% - 38px) / 2);
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.12);

    @media (max-width: $third) {
      width: 100%;
    }

    video {
      width: 100%;
      height: 100%;
      background-color: #eee;
    }
  }

  &__disclaimer {
    margin-top: 16px;
    font-family: $Merriweather;
    font-size: 14px;
    font-weight: 300;
    font-style: italic;
    line-height: 1.43;
    color: #424242;
  }
}
