@import './../../styles/variables.scss';

.BranchTransformation {
  .first-info {
    margin-top: 120px;

    @media (max-width: $first) {
      margin-top: 96px;
    }

    @media (max-width: $second) {
      margin-top: 72px;
    }

    @media (max-width: $third) {
      margin-top: 56px;
    }
  }

  h2 {
    margin-top: 8px;
    margin-bottom: 16px;
    text-transform: uppercase;
  }

  h4 {
    color: #333333;

    &.--some-important {
      margin-top: 64px;

      @media (max-width: $first) {
        margin-top: 48px;
      }

      @media (max-width: $second) {
        margin-top: 40px;
      }
    }
  }

  .description {
    max-width: 730px;
  }

  &__disclaimer {
    max-width: 730px;
    margin-top: 40px;

    @media (max-width: $third) {
      margin-top: 32px;
    }
  }

  &__disclaimer-data {
    max-width: 730px;
    margin-bottom: 120px;

    @media (max-width: $first) {
      margin-bottom: 96px;
    }

    @media (max-width: $second) {
      margin-top: 32px;
      margin-bottom: 72px;
    }

    @media (max-width: $third) {
      border-top: 1px solid #d8d8d8;
      padding-top: 24px;
    }
  }

  .hero-wrapper {
    height: 550px;
    margin-top: 120px;
    margin-bottom: 120px;
    background-color: #1b2a55;

    @media (max-width: $first) {
      margin-top: 96px;
      margin-bottom: 96px;
      height: 400px;
    }

    @media (max-width: $second) {
      margin-top: 72px;
      margin-bottom: 72px;
      height: 280px;
    }

    @media (max-width: $third) {
      margin-top: 56px;
      margin-bottom: 56px;
      height: 166px;
    }

    .container-slim {
      height: 100%;

      .hero {
        height: 100%;
        background-size: cover;
        background-image: url(../../assets/branch-hero.jpg);
        background-position: center center;
      }
    }
  }

  .data {
    display: flex;
    margin-bottom: 56px;

    @media (max-width: $second) {
      margin-bottom: 48px;
    }

    @media (max-width: $third) {
      margin-bottom: 24px;
      flex-direction: column;
    }

    .left {
      width: 167px;
      flex-shrink: 0;
      padding-right: 34px;
      border-right: 1px solid #d8d8d8;

      @media (max-width: $second) {
        padding-right: 12px;
      }

      @media (max-width: $third) {
        width: 100%;
        padding-right: 0;
        border-right: none;
        border-bottom: 1px solid #d8d8d8;
        padding-bottom: 24px;
        margin-bottom: 24px;
      }

      .label {
        margin-bottom: 16px;
      }

      .value {
        color: #424242;
      }

      .--scrum-team {
        margin-top: 16px;
      }

      .--year {
        margin-top: 24px;
      }
    }

    .right {
      padding-left: 40px;

      @media (max-width: $second) {
        padding-left: 18px;
      }

      @media (max-width: $third) {
        padding-left: 0;
      }

      .value {
        margin-top: 16px;
        margin-bottom: 24px;
        color: #424242;
        max-width: 520px;
      }

      .tasks {
        color: #424242;
        max-width: 520px;
      }
    }
  }

  .wrapper {
    padding: 120px 0;

    @media (max-width: $first) {
      padding: 96px 0;
    }

    @media (max-width: $second) {
      padding: 72px 0;
    }

    @media (max-width: $third) {
      padding: 56px 0;
    }

    &.--grey {
      background-color: rgba(27, 42, 85, 0.06);
    }

    p {
      margin-top: 24px;
      font-family: $Merriweather;
      font-size: 18px;
      font-weight: 300;
      line-height: 1.67;
      color: #424242;
      max-width: 730px;
    }
  }

  .boxes {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
    margin-bottom: 65px;
    flex-wrap: wrap;

    @media (max-width: $first) {
      margin-bottom: 48px;
    }

    @media (max-width: $second) {
      margin-bottom: 32px;
    }

    &.--no-bottom-margin {
      margin-bottom: 0;
    }

    &.--some-important {
      .boxes__box {
        &:nth-child(3) {
          @media (max-width: $first) {
            margin-top: 20px;
          }
        }

        &:last-child {
          margin-top: 20px;
        }
      }
    }

    &.--pilot {
      .boxes__box {
        &:nth-child(3) {
          @media (max-width: $first) {
            margin-top: 20px;
          }
        }

        &:nth-child(4),
        &:nth-child(5) {
          margin-top: 20px;
        }

        &:last-child {
          border: none;
          padding: 0;
          margin: 0;
        }
      }
    }

    &__box {
      width: calc((100% - 40px) / 3);
      border: 1px solid #979797;
      padding: 32px;

      @media (max-width: $first) {
        width: calc((100% - 20px) / 2);
      }

      @media (max-width: $second) {
        width: 100%;
        margin-top: 24px;
      }

      &:first-child {
        @media (max-width: $second) {
          margin-top: 0;
        }
      }

      &:last-child {
        @media (max-width: $first) {
          margin-top: 20px;
        }

        @media (max-width: $second) {
          margin-top: 24px;
        }
      }
    }

    &__title {
      color: #424242;
    }

    &__description {
      margin-top: 20px;
      color: #424242;
    }
  }

  .ImagesContainerFullWidth {
    .photos {
      background-color: #1b2a55;
    }
  }

  .ATM {
    display: flex;

    @media (max-width: $first) {
      flex-direction: column;
    }

    .left {
      padding-right: 56px;
    }

    .right {
      width: 437px;

      @media (max-width: $first) {
        width: 100%;
        padding: 32px;
        margin-top: 56px;
        background-color: #fff;
      }

      @media (max-width: $third) {
        width: 100%;
        padding: 0;
      }

      img {
        max-width: 100%;
        max-height: 100%;

        @media (max-width: $first) {
          margin: 0 auto;
          display: block;
          max-width: 437px;
        }

        @media (max-width: $second) {
          max-width: 100%;
        }
      }

      .ATM__disclaimer {
        margin-top: 16px;

        @media (max-width: $first) {
          display: none;
        }
      }
    }

    .ATM__disclaimer-mobile {
      display: none;
      margin-top: 16px;

      @media (max-width: $first) {
        display: block;
      }
    }
  }

  .FullWidthImage {
    &.--wireframe {
      img {
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.24);
      }
    }
  }

  .Box,
  .BoxFull,
  .FullWidthImage,
  .ImagesContainerFullWidth,
  .ThreeBox,
  .Video,
  .FourPhotos {
    margin-top: 80px;
    margin-bottom: 80px;

    @media (max-width: $first) {
      margin-top: 56px;
      margin-bottom: 56px;
    }

    @media (max-width: $second) {
      margin-top: 48px;
      margin-bottom: 48px;
    }

    @media (max-width: $third) {
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }
}
