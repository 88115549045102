@import '../../styles/variables.scss';

.FullWidthImage {
  img {
    width: 100%;
  }

  .disclaimer {
    margin-top: 16px;
    color: #424242;
  }
}
