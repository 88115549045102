@import './../../styles/variables.scss';

.NoFilter {
  .first-info {
    margin-top: 120px;

    @media (max-width: $first) {
      margin-top: 96px;
    }

    @media (max-width: $second) {
      margin-top: 72px;
    }

    @media (max-width: $third) {
      margin-top: 56px;
    }
  }

  h2 {
    margin-top: 8px;
    margin-bottom: 16px;
    text-transform: uppercase;
  }

  h4 {
    color: #333333;
  }

  .description {
    max-width: 730px;
  }

  .hero-wrapper {
    height: 550px;
    margin-top: 120px;
    margin-bottom: 120px;
    background-color: #000;

    @media (max-width: $first) {
      margin-top: 96px;
      margin-bottom: 96px;
      height: 400px;
    }

    @media (max-width: $second) {
      margin-top: 72px;
      margin-bottom: 72px;
      height: 280px;
    }

    @media (max-width: $third) {
      margin-top: 56px;
      margin-bottom: 56px;
      height: 166px;
    }

    .container-slim {
      height: 100%;

      .hero {
        height: 100%;
        background-size: cover;
        background-image: url(../../assets/nofilter-hero.jpg);
        background-position: center center;
      }
    }
  }

  .data {
    display: flex;
    margin-bottom: 120px;

    @media (max-width: $first) {
      margin-bottom: 96px;
    }

    @media (max-width: $second) {
      margin-bottom: 72px;
    }

    @media (max-width: $third) {
      margin-bottom: 56px;
      flex-direction: column;
    }

    .left {
      width: 167px;
      padding-right: 34px;
      border-right: 1px solid #d8d8d8;

      @media (max-width: $second) {
        padding-right: 12px;
      }

      @media (max-width: $third) {
        width: 100%;
        padding-right: 0;
        border-right: none;
        border-bottom: 1px solid #d8d8d8;
        padding-bottom: 24px;
        margin-bottom: 24px;
      }

      .label {
        margin-bottom: 16px;
      }

      .value {
        color: #424242;
      }

      .--year {
        margin-top: 24px;
      }
    }

    .right {
      padding-left: 40px;

      @media (max-width: $second) {
        padding-left: 18px;
      }

      @media (max-width: $third) {
        padding-left: 0;
      }

      .value {
        margin-top: 16px;
        margin-bottom: 24px;
        color: #424242;
      }

      .tasks {
        color: #424242;
        max-width: 520px;
      }
    }
  }

  .wrapper {
    padding: 120px 0;

    @media (max-width: $first) {
      padding: 96px 0;
    }

    @media (max-width: $second) {
      padding: 72px 0;
    }

    @media (max-width: $third) {
      padding: 56px 0;
    }

    &.--grey {
      background-color: rgba(0, 0, 0, 0.06);
    }

    p {
      margin-top: 24px;
      font-family: $Merriweather;
      font-size: 18px;
      font-weight: 300;
      line-height: 1.67;
      color: #424242;
      max-width: 730px;
    }
  }

  .boxes {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
    margin-bottom: 65px;

    @media (max-width: $first) {
      flex-wrap: wrap;
      margin-bottom: 48px;
    }

    @media (max-width: $second) {
      margin-bottom: 32px;
    }

    &.--no-bottom-margin {
      margin-bottom: 0;
    }

    &__box {
      width: calc((100% - 40px) / 3);
      border: 1px solid #979797;
      padding: 32px;

      @media (max-width: $first) {
        width: calc((100% - 20px) / 2);
      }

      @media (max-width: $second) {
        width: 100%;
        margin-top: 24px;
      }

      &:first-child {
        @media (max-width: $second) {
          margin-top: 0;
        }
      }

      &:last-child {
        @media (max-width: $first) {
          margin-top: 20px;
        }

        @media (max-width: $second) {
          margin-top: 24px;
        }
      }
    }

    &__title {
      color: #424242;
    }

    &__description {
      margin-top: 20px;
      color: #424242;
    }
  }

  .design-system {
    padding: 48px;
    margin-top: 80px;
    background-color: #333;

    @media (max-width: $first) {
      padding: 32px;
      margin-top: 56px;
    }

    @media (max-width: $second) {
      margin-top: 48px;
    }

    @media (max-width: $third) {
      margin-top: 40px;
      padding: 16px;
    }

    .photo {
      display: inline-block;
      width: calc((100% - 30px) / 3);
      margin-right: 15px;
      margin-bottom: 15px;

      @media (max-width: $first) {
        width: calc((100% - 22px) / 3);
        margin-right: 11px;
        margin-bottom: 11px;
      }

      @media (max-width: $second) {
        width: calc((100% - 14px) / 3);
        margin-right: 7px;
        margin-bottom: 7px;
      }

      @media (max-width: $third) {
        width: calc((100% - 9px) / 2);
        margin-right: 9px;
        margin-bottom: 9px;
      }

      img {
        max-width: 100%;
      }

      &:nth-child(3n + 3) {
        margin-right: 0;
      }

      @media (max-width: $third) {
        &:nth-child(3n + 3) {
          margin-right: 9px;
        }

        &:nth-child(2n + 2) {
          margin-right: 0;
        }
      }
    }

    &__disclaimer {
      margin-bottom: 80px;
      margin-top: 16px;
      color: #424242;

      @media (max-width: $first) {
        margin-bottom: 56px;
      }

      @media (max-width: $second) {
        margin-bottom: 48px;
      }

      @media (max-width: $third) {
        margin-bottom: 40px;
      }
    }
  }

  .Box,
  .BoxFull,
  .FullWidthImage,
  .ImagesContainerFullWidth,
  .ThreeBox,
  .Video,
  .FourPhotos {
    margin-top: 80px;
    margin-bottom: 80px;

    @media (max-width: $first) {
      margin-top: 56px;
      margin-bottom: 56px;
    }

    @media (max-width: $second) {
      margin-top: 48px;
      margin-bottom: 48px;
    }

    @media (max-width: $third) {
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }

  .ImagesContainerFullWidth {
    &.--proto-personas {
      margin-bottom: 0;
    }
  }

  .Video {
    &.--social {
      margin-bottom: 0;
    }
  }
}
