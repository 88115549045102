@import '../../styles/variables.scss';

.ImagesContainerFullWidth {
  .photos {
    padding: 48px;
    background-color: #fff;

    @media (max-width: $first) {
      padding: 32px;
    }

    @media (max-width: $third) {
      padding: 16px;
    }

    .photo {
      width: calc(50% - 10px);
      box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.12);

      &:first-child {
        margin-right: 20px;

        @media (max-width: $second) {
          margin-right: 0;
          margin-bottom: 20px;
        }
      }

      @media (max-width: $second) {
        width: 100%;
      }
    }
  }

  .disclaimer {
    margin-top: 16px;
    color: #424242;
  }
}
