@import '../../styles/variables.scss';

.FourPhotos {
  .photos {
    display: flex;
    padding: 48px;
    background-color: #fff;

    @media (max-width: $first) {
      padding: 32px;
    }

    @media (max-width: $second) {
      flex-direction: column;
    }

    @media (max-width: $third) {
      padding: 16px;
    }

    .left,
    .right {
      display: flex;
      width: calc((100% - 48px) / 2);

      @media (max-width: $second) {
        width: 100%;
      }

      .photo {
        width: calc((100% - 16px) / 2);
        box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.18);

        @media (max-width: $first) {
          width: calc((100% - 12px) / 2);
        }

        @media (max-width: $second) {
          width: calc((100% - 18px) / 2);
        }

        @media (max-width: $third) {
          width: calc((100% - 12px) / 2);
        }

        &:first-child {
          margin-right: 16px;

          @media (max-width: $first) {
            margin-right: 12px;
          }

          @media (max-width: $second) {
            margin-right: 18px;
          }

          @media (max-width: $third) {
            margin-right: 12px;
          }
        }

        img {
          max-width: 100%;
          display: block;
        }
      }
    }

    .left {
      margin-right: 48px;

      @media (max-width: $second) {
        margin-right: 0;
        margin-bottom: 40px;
      }

      @media (max-width: $third) {
        margin-bottom: 24px;
      }
    }
  }

  .disclaimer {
    margin-top: 16px;
    color: #424242;
  }
}
