@import './../../styles/variables.scss';

.Footer {
  border-top: 1px solid #d8d8d8;

  a {
    line-height: 150px;
    margin-right: 40px;

    @media (max-width: $first) {
      line-height: 132px;
    }

    @media (max-width: $second) {
      margin-right: 21px;
    }

    @media (max-width: $third) {
      line-height: 100px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .contact {
    .email {
      @media (max-width: $third) {
        display: none;
      }
    }

    .text {
      display: none;

      @media (max-width: $third) {
        display: unset;
      }
    }
  }
}
