@import './../../styles/variables.scss';

.Header {
  height: 64px;

  @media (max-width: $second) {
    height: 40px;
  }

  @media (max-width: $third) {
    height: 32px;
  }

  .logo {
    display: flex;
    height: 100%;
    align-items: flex-end;

    .icon {
      width: 24px;
      height: 6px;
      background-color: #000;
      margin-right: 8px;
      margin-bottom: 5px;

      @media (max-width: $third) {
        width: 16px;
      }
    }

    .text {
      font-family: $Rubik;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.14;
      color: #333;
      text-transform: uppercase;

      @media (max-width: $third) {
        font-size: 12px;
      }

      &.dani,
      &.portfolio {
        flex-shrink: 0;
      }

      &.project {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.breadcrumb {
        &:before {
          content: '/';
          display: block;
          float: left;
          margin-left: 10px;
          margin-right: 10px;
        }
      }
    }
  }
}
