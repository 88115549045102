@import '../../styles/variables.scss';

.ThreeBox {
  &__wrapper {
    background-color: #333;
    padding: 48px;

    @media (max-width: $first) {
      padding: 32px;
    }

    @media (max-width: $third) {
      padding: 16px;
    }
  }

  &__photos {
    width: calc(378px + 14px + 408px);
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;

    @media (max-width: $third) {
      width: 100%;
      max-width: 100%;
      flex-direction: column;
    }
  }

  &__left {
    width: 378px;
    margin-right: 14px;

    @media (max-width: $third) {
      width: 100%;
      margin-right: 0;
    }
  }

  &__right {
    width: 408px;

    @media (max-width: $third) {
      width: 100%;
      margin-top: 10px;
    }
  }

  &__disclaimer {
    margin-top: 16px;
    font-family: $Merriweather;
    font-size: 14px;
    font-weight: 300;
    font-style: italic;
    line-height: 1.43;
    color: #424242;
  }

  img {
    width: 100%;
    height: 100%;
  }
}
