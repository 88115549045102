@import './../../styles/variables.scss';

.Portfolio {
  h2 {
    margin-top: 80px;

    @media (max-width: $first) {
      margin-top: 56px;
    }

    @media (max-width: $second) {
      margin-top: 48px;
    }

    @media (max-width: $third) {
      margin-top: 40px;
    }
  }

  .project {
    display: flex;
    margin-top: 80px;
    height: 348px;
    border: 1px solid #979797;

    @media (max-width: $first) {
      margin-top: 56px;
    }

    @media (max-width: $second) {
      margin-top: 48px;
      flex-direction: column;
      height: 632px;
    }

    @media (max-width: $third) {
      margin-top: 40px;
    }

    .data {
      display: flex;
      flex-direction: column;
      width: 50%;
      padding: 32px;
      height: 100%;

      @media (max-width: $second) {
        width: 100%;
        height: 50%;
      }

      .name {
        margin-top: 10px;
        text-transform: uppercase;
        hyphens: auto;
      }

      .description {
        flex: 1;
        margin-top: 15px;
        color: #424242;
        max-width: 360px;

        @media (max-width: $second) {
          font-size: 16px;
        }

        @media (max-width: $third) {
          max-width: 100%;
        }
      }

      .UI-link {
        @media (max-width: $third) {
          font-size: 14px;
        }
      }
    }

    .image {
      width: 50%;
      height: 100%;
      background-size: cover;
      background-position: center center;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 40px;

      @media (max-width: $first) {
        padding: 32px;
      }

      @media (max-width: $second) {
        padding: 24px;
        width: 100%;
        height: 50%;
      }

      @media (max-width: $third) {
        padding: 16px;
      }

      &.--nofilter {
        background-image: url(../../assets/nofilter-hero-small.jpg);
        background-position: top center;
      }

      &.--branch {
        background-image: url(../../assets/branch-hero.jpg);
      }

      &.--nutrition {
        background-color: #50d2c2;
        padding-bottom: 0;
      }

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  .Footer {
    margin-top: 120px;

    @media (max-width: $first) {
      margin-top: 96px;
    }

    @media (max-width: $second) {
      margin-top: 72px;
    }

    @media (max-width: $third) {
      margin-top: 56px;
    }
  }
}
