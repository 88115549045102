@import '../../styles/variables.scss';

.BoxFull {
  .img-wrapper {
    padding: 48px;
    background-color: #333;

    @media (max-width: $first) {
      padding: 32px;
    }

    @media (max-width: $second) {
      padding: 32px;
    }

    @media (max-width: $third) {
      padding: 16px;
    }

    img {
      max-width: 100%;
    }
  }

  .disclaimer {
    margin-top: 16px;
    color: #424242;
  }
}
