@import '../../styles/variables.scss';

.Box {
  .img-wrapper {
    padding: 48px;
    background-color: rgba(0, 0, 0, 0.06);

    @media (max-width: $first) {
      padding: 32px;
    }

    @media (max-width: $third) {
      padding: 0;
      background-color: transparent;
    }

    .img {
      max-width: 746px;
      margin: 0 auto;

      img {
        max-width: 100%;
      }
    }
  }

  .disclaimer {
    margin-top: 16px;
    color: #424242;
  }
}
